import React from 'react';

import {
  SubTitleBox,
  SubTitleBoxesContainer,
  SubTitleBoxButton,
  SubTitleBoxLink,
  SubTitleBoxParagraph,
  SubTitleBoxTitle,
} from './styles';

const SubTitleBoxes = () => (
  <SubTitleBoxesContainer>
    <SubTitleBox id="who-we-are">
      <SubTitleBoxTitle>WHO WE ARE</SubTitleBoxTitle>
      <SubTitleBoxParagraph>
        We are a diverse group of visionaries who believe that art wields the power to shape a
        better world and we are part of that endeavour.
      </SubTitleBoxParagraph>
    </SubTitleBox>
    <SubTitleBox>
      <SubTitleBoxTitle>CONTACT US</SubTitleBoxTitle>
      <SubTitleBoxLink>
        Interested to know more or get involved? Get in touch:{' '}
        <SubTitleBoxLink style={{ display: 'inline-block' }} href="mailto:info@community-arts.net">
          info@community-arts.net
        </SubTitleBoxLink>
      </SubTitleBoxLink>

      <SubTitleBoxParagraph size="s">Gertrude-Fröhlich-Sandner-Straße</SubTitleBoxParagraph>
      <SubTitleBoxParagraph size="s">1/Top 5, 1100 Vienna.</SubTitleBoxParagraph>
      <SubTitleBoxButton href="mailto:info@community-arts.net">Contact us</SubTitleBoxButton>
    </SubTitleBox>
  </SubTitleBoxesContainer>
);

export default SubTitleBoxes;
